@keyframes dot-keyframes {
  0% {
    background-size: 10% 100%;
  }

  50% {
    background-size: 50% 100%;
  }

  100% {
    background-size: 100% 100%;
  }
}
.ajax-loader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	/* presto! */
	z-index: 9999;
}

.loading-dots {
  animation: dot-keyframes 4s infinite ease-in-out;
  background-image: url('../../style/images/loading_bar.gif');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}