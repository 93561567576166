@charset "UTF-8";@font-face {
	font-family: 'Roboto Light';
	src: url(../fonts/Roboto-Light.eot);
	src: url(../fonts/Roboto-Light.eot?#iefix) format('embedded-opentype'),url(../fonts/Roboto-Light.woff) format('woff'),url(../fonts/Roboto-Light.ttf) format('truetype')
}

@font-face {
	font-family: 'Roboto Bold';
	src: url(../fonts/Roboto-Bold.eot);
	src: url(../fonts/Roboto-Bold.eot?#iefix) format('embedded-opentype'),url(../fonts/Roboto-Bold.woff) format('woff'),url(../fonts/Roboto-Bold.ttf) format('truetype')
}

@font-face {
	font-family: 'Roboto Black';
	src: url(../fonts/Roboto-Black.eot);
	src: url(../fonts/Roboto-Black.eot?#iefix) format('embedded-opentype'),url(../fonts/Roboto-Black.woff) format('woff'),url(../fonts/Roboto-Black.ttf) format('truetype')
}

@import url(http://fonts.googleapis.com/css?family=Roboto);.set-radius-zero {
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0
}

.block-lessons-series .media-holder {
    background: #555555;
    min-height: 180px;
    text-align: center;
    position: relative;
    overflow: hidden;
}
